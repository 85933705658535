import React from 'react'
import { Link, graphql } from 'gatsby'

import { Logo } from '@components/Logo'
import Layout from '@components/Layout'
import SEO from '@components/SEO'
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={`${frontmatter.title} | radioradio.radio`} />
      <div className="single">
        {/* Head */}
        <div className="single__head">
          <div className="padding">
            <Link to="/blog">
              <span>See all articles</span>
            </Link>
          </div>
          {/* Visual */}
          <div className="visuals">
            {/* <div
              className="image"
              style={{ backgroundImage: `url(${frontmatter.Thumbnail})` }}
            ></div> */}
            <img className="img" src={frontmatter.Thumbnail} alt="" />
            <div className="marquee">
              <Logo className="marquee__inner" stretch idle={false} />
            </div>
          </div>
          {/* Visual */}

          {/* Title */}
          <div className="padding">
            <h1 className="title">{frontmatter.title}</h1>
            <p>{frontmatter.date}</p>
          </div>
          <span className="line"></span>
        </div>
        {/* Head  */}

        {/* Article */}
        <div className="padding">
          <div className="single__body">
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              className="single__col"
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        Thumbnail
      }
    }
  }
`
